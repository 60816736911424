import { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { PlusIcon } from '../../../../assets/icons';
import { EllipsisVerticalIcon, FlagIcon, MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import Flyout, { useFlyout } from 'components/Flyout/Flyout';
import New from './New';
import Squawk from 'components/Squawk/Squawk';
import { GetSquawksDocument } from 'graphql/generated';
import { useSession } from 'contexts/SessionContext';
import Button from 'components/Button/Button';
import Tabs from 'components/Tabs/Tabs';
import useSaveState from 'hooks/useSaveState';
import { Menu, Transition } from '@headlessui/react';
import { OrgSubtypes } from 'utils/orgTypes';
import Paywall from 'components/Paywall/Paywall';
import isMobileApp from 'utils/isMobileApp';
import Components from './Components';
import { useNavigate } from 'react-router-dom';
import { AbilityContext } from 'contexts/AbilityContext';
import { permissionHandler } from 'utils/PermissionHandler';
import sanitizeQuery from 'utils/querySanitizer';

const Squawks = () => { 
  const { craftId, organizationSubtype } = useSession();
  // check if craftId is undefined and navigate to crafts page
  const navigate = useNavigate();
  if(!craftId) navigate('/crafts');

  const { flyoutProps, updateFlyout, closeFlyout } = useFlyout();
  const [activeTab, setActiveTab] = useSaveState('squawksTab', 'All');
  const tabs = [ 'All', 'Open', 'Closed' ];
  const [query, setQuery] = useState('');
  const { data, refetch } = useQuery(GetSquawksDocument, { variables: 
    { craftId },
    skip: craftId === undefined
  });
  const ability = useContext(AbilityContext);
  const canManageSquawks = permissionHandler('Manage Squawks', ability);

  useEffect(() => {
    const sanitizedQuery = sanitizeQuery(query);
    const delayDebounceFn = setTimeout(() => {
      refetch(
        {
          where: { ...(query && { title: { startsWith: sanitizedQuery } }), craftId: { equals: craftId } },
        }
      );
    }, 400);
    return () => clearTimeout(delayDebounceFn);
  }, [query, refetch]);

  const tableData = useMemo(() => {
    let statuses = [];
    switch (activeTab) {
      case 'All':
        return data?.squawks;
      case 'Open':
        statuses = ['in_progress', 'open', 'planned', 'stalled'];
        break;
      case 'Closed':
        statuses = ['resolved'];
        break;
    }
    return data?.squawks?.filter((item) => statuses.includes(item?.status.toLowerCase()));
  }, [data?.squawks, activeTab]);

  return (
    <>
      <Flyout {...flyoutProps} />
      <div>
        <div className="flex flex-col">
          {/* Header */}
          <div className="flex justify-between items-center bg-white p-6 border border-slate-300 rounded mb-2 shadow-blue">
            <div className="flex items-center">
              <FlagIcon className="h-8 w-8 bg-brand-pale mr-2 text-brand-electric rounded p-[0.375rem]" />
              <h1 className="text-left font-bold text-brand-dark text-2xl md:text-2xl">Squawks</h1>
            </div>
            {organizationSubtype !== OrgSubtypes.FREE &&
            <div className="flex justify-end gap-2 flex-wrap">
              <div className="relative flex items-center">
                <MagnifyingGlassIcon className="h-4 w-4 absolute left-2 text-slate-400" />
                <input
                  className="border border-slate-300 bg-slate-50 rounded h-8 placeholder:text-slate-400 px-2 pl-7 w-full"
                  placeholder="Search"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
              {canManageSquawks && <Button text="Add Squawk" color="navy" size="xs" icon={<PlusIcon className="h-3 w-3 mr-1" />}
                onClick={ () => updateFlyout({ title: 'Add Squawk', content: <New closeFlyout={() => {
                  refetch();
                  closeFlyout(); 
                }}/> }) } /> }
              <Menu
                as="div"
                className="flex items-center justify-center relative group transition ml-2 w-[28px] border border-white rounded hover:border-slate-200 hover:shadow-inner">
                <Menu.Button className="">
                  <EllipsisVerticalIcon className="h-5 w-5 text-slate-500 cursor-pointer" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95">
                  <Menu.Items className="origin-top-right absolute top-full right-0 mt-2 rounded shadow-blue-lg border border-slate-300 bg-white focus:outline-none z-[99] w-36">
                    <Menu.Item>
                      {({ active }) => (
                        isMobileApp() ?
                        <button onClick={async () => { await navigator.share({ title:"Export Squawks from Coflyt", url: process.env.REACT_APP_API_URL+"/squawks/"+craftId, text: "Export Squawks from Coflyt"}) } } className='block px-4 py-3 text-sm text-slate-700 w-full text-right rounded'>Export</button>
                        :
                        <a
                          href={process.env.REACT_APP_API_URL+"/squawks/"+craftId}
                          className={`${active ? 'bg-slate-100' : ''} block px-4 py-3 text-sm text-slate-700 w-full text-right rounded`}
                          download>
                          Export
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                        onClick={() => updateFlyout({ title: 'Components', content: <Components/> }) }
                        className={`${active ? 'bg-slate-100' : ''} block px-4 py-3 text-sm text-slate-700 w-full text-right rounded`}
                        >
                          Components
                        </button>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>}
          </div>
        </div>
        {organizationSubtype === OrgSubtypes.FREE ? <Paywall />
        :
        <>
          {/* Squawks List */}
          <div className={`flex`}>
            <div className="w-full">
              <div className="rounded bg-white rounded border border-slate-300">
                <div className="flex justify-between items-center border-b pt-1 bg-slate-50 w-full px-4 mb-3 rounded-t">
                  <Tabs setActiveItem={setActiveTab} activeItem={activeTab} items={tabs} />
                </div> 
                {tableData?.map((squawk, index, arr) => {
                  let last = arr.length - 1 === index ? true : false;
                  return <Squawk key={index} { ...{ squawk, updateFlyout, last, closeFlyout, refetch } }/>;
                })}
                {tableData?.length === 0 && <div className="flex justify-center items-center p-4">
                  <h1 className="text-center text-slate-500">No Squawks Found</h1>
                </div>}
              </div>
            </div>
          </div>
        </>
        }
      </div>
    </>
  );
};

export default Squawks;
