import Button from "components/Button/Button";
import Tabs from "components/Tabs/Tabs";
import { Craft, GetCraftsReservationsDocument, GetCraftsWithUsersDocument, GetOrganizationSettingsDocument } from "graphql/generated";
import { useEffect, useMemo, useState } from "react";
import { applyTimezone, formatCalendarDay, formatCalendarMonth, formatCalendarTimeUTC, formatCalendarWeek } from "utils/formatter";
import Day from "./views/Day";
import Month from "./views/Month";
import Week from "./views/Week";
import SegmentControl from "components/SegmentControl/SegmentControl";
import { useLayoutEffect } from "react";
import { useSession } from "contexts";
import { useQuery } from "@apollo/client";
import View from "../../View";
import { useRefetchContext } from "contexts/RefetchContext";

function Calendar({ updateFlyout, closeFlyout }) {
    const { organizationId, user } = useSession();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const { data : { organization } = { } } = useQuery(GetOrganizationSettingsDocument, { variables: { id: organizationId } });
    const colorTiedToCraft = organization?.reservationSettings?.showColorTiedToCraft;

    const { data, refetch } = useQuery(GetCraftsReservationsDocument, {
        variables: { organizationId,  selectedDate: selectedDate.toISOString() },
        pollInterval: 600000, // 10 minutes
    });
    const { data: { crafts } = { craft: undefined } } = useQuery(GetCraftsWithUsersDocument, {
      variables: { organizationId },
      pollInterval: 0,
    });
    
    // Refetch function
    const { setRefetchList } = useRefetchContext();
    useEffect(() => {
        setRefetchList(() => refetch);
    }, [refetch, setRefetchList]);

    const [events, setEvents] = useState([]);

    
    const [activeTab, setActiveTab] = useState("Month");
    
    const tabs = ["Month", "Week", "Day"];
    const userList = useMemo(() => {
        const tempUsers = [];
        crafts?.forEach((craft) => {
          craft?.userProfiles.forEach((profile) => {
            if (!tempUsers.includes(profile?.user?.id)) {
              tempUsers.push(profile?.user?.id);
            }
          });
        });
        return tempUsers;
      }
    , [crafts]);
    const craftEvents = useMemo(() => {
        if (!data?.crafts) return [];
        
        const tempEvents = [];
        data.crafts.forEach((craft) => {
          craft.reservations.forEach((res) => {
            tempEvents.push({
              ...res,
              to: applyTimezone(res.to, Number(res.timezone), Number(user.timezone)),
              from: applyTimezone(res.from, Number(res.timezone), Number(user.timezone)),
              userId: res.userId,
              name: `${res.allDay ? 'All Day(s)' : formatCalendarTimeUTC(applyTimezone(res?.from, Number(res?.timezone), Number(user.timezone)).toISOString())} ${res.isMaintenance ? 'Maintenance' : res.pilotName ?? ''}\n${craft.name}-${craft.tailNumber}`
            });
          });
        });
        return tempEvents.sort((a, b) => {
          const aDate = new Date(a.from);
          const bDate = new Date(b.from);
          return (+bDate) - (+aDate);
        });
      }, [data]);
      
    useEffect(() => {
        setEvents(craftEvents);
    }, [craftEvents]);
    
    const showView = (reservation)=> {
        updateFlyout({ title: 'Reservation', content: <View refreshData={refetch} id={reservation?.id} closeView={closeFlyout} /> });
    }

    const getTimeTitle = (mode, time):string=>{
        if(mode=="Month"){return formatCalendarMonth(time)}
        else if(mode=="Week"){return formatCalendarWeek(time)}
        else if(mode=="Day"){return formatCalendarDay(time)};
        return "";
    }
    const paginateDate = (mode, amount)=>{
        if(mode==="Month"){setSelectedDate(new Date(selectedDate.getFullYear(), selectedDate.getMonth()+amount, selectedDate.getDate()))}
        else if(mode=="Week"){setSelectedDate(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate()+(amount*7) ))}
        else if(mode=="Day"){setSelectedDate(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate()+amount ))};
    }
    const isToday = (mode, date:Date):boolean=>{
        const today = new Date();
        if(mode==="Month"){return selectedDate.getFullYear()===today.getFullYear() && selectedDate.getMonth()===today.getMonth() }
        else if(mode==="Week"){
            var sunday = new Date(date.getFullYear(), date.getMonth(), (date.getDate()-date.getDay()));
            var saturday = new Date(date.getFullYear(), date.getMonth(), (date.getDate()-(date.getDay()-6)));
            return (today > sunday && today < saturday);
        }else if(mode==="Day"){return selectedDate.getFullYear()===today.getFullYear() && selectedDate.getMonth()===today.getMonth() && selectedDate.getDate()===today.getDate() };
        return false;
    }

    return (
      <div className="rounded-md bg-offwhite">
        {/* Header/Controls */}
        <div className="flex justify-between flex-wrap items-center bg-white rounded-t border-slate-300 w-full px-5 py-3">
            {/* left buttons */}
            <div className="flex grow">
                <Button text={"<"} 
                color="navy"
                size="xs"
                onClick={()=>paginateDate(activeTab, -1)}
                className="p-2 mr-2 rounded bg-brand text-white hover:bg-brand/50" />
                <Button text={">"} 
                color="navy"
                size="xs"
                onClick={()=>paginateDate(activeTab, 1)}
                className="p-2 mr-2 rounded bg-brand text-white hover:bg-brand/50" />
                <Button text={"Today"} 
                color="navy"
                size="xs"
                onClick={()=>setSelectedDate(new Date())}
                className={(isToday(activeTab, selectedDate)?"bg-brand/50 border-brand/50":"bg-brand") + " p-2 rounded text-white hover:bg-brand/50"} />
            </div>
            {/* title */}
            <div className="flex justify-center grow">
                <span className="text-2xl font-semibold">{getTimeTitle(activeTab, selectedDate)}</span>
            </div>
            {/* tab bar */}
            <div className="flex justify-end grow">
                <SegmentControl onChange={setActiveTab} activeItem={activeTab} items={tabs}  />
            </div>
        </div>
        {/* View */}
        <div>
            { activeTab=="Month" && <Month 
                  onEventSelected={showView} 
                  events={events} 
                  date={selectedDate}
                  crafts={data?.crafts ?? [] as any}
                  userList={userList ?? []} colorTiedToCraft={colorTiedToCraft} /> }
            { activeTab === "Week" ? <Week onEventSelected={showView} events={events} date={selectedDate} 
                  userList={userList ?? []} 
                  crafts={data?.crafts ?? [] as any}
                  colorTiedToCraft={colorTiedToCraft} /> : null }
            { activeTab=="Day" && <Day crafts={data?.crafts ?? [] as any} onEventSelected={showView} events={events} 
                  date={selectedDate} userList={userList} 
                  colorTiedToCraft={colorTiedToCraft} /> }
        </div>
      </div>
    );
  }
  
  export default Calendar;