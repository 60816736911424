import { StarIcon } from "@heroicons/react/24/solid";
import Card from "components/Card/Card";
import { format } from "date-fns";
import { Link } from "react-router-dom";

const stripHtmlTags = (htmlString) => {
  // Create a temporary div element to hold the HTML
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlString;
  
  // Get the text content of the div, which will not include any HTML tags
  return tempDiv.textContent || tempDiv.innerText || "";
};

export default function AnnouncementEntry(
  { title, content, createdAt, updatedAt, id, authorName, pinned } : 
  { title: string, content: string, createdAt: Date, updatedAt: Date, id: string, authorName:string, pinned: boolean }) {
  return (
    <div id={id} className="relative -mt-3">
      <Link to={id}><Card className='border-slate-300 shadow-none'>
        <dl>
          { pinned && <StarIcon className="h-6 w-6 text-brand-electric absolute top-5 right-3" />}
          <div className="p-2 flex items-center justify-center w-full">
            <h1 className="font-bold text-lg md:text-xl">{title}</h1>
          </div>
          { createdAt && <dt className="mb-2 text-sm text-center">Published {format(createdAt, 'MM-dd-yyyy')}</dt>}
          <dt className="mb-2 text-sm text-center">By: {authorName}</dt>
          <div className="p-2">
            <dt className="mb-2 truncate">{stripHtmlTags(content)}&nbsp;</dt>
          </div>
        </dl>
      </Card></Link>
    </div>
  );
}