import { useContext, useEffect, useState } from 'react';
import { Squares2X2Icon, BriefcaseIcon, BookOpenIcon, DocumentCheckIcon, GlobeAmericasIcon, WrenchIcon, ChartBarIcon, FlagIcon, MegaphoneIcon, CalendarDaysIcon } from '@heroicons/react/24/outline';
import { Squares2X2Icon as VGISolid, BriefcaseIcon as BISolid, FlagIcon as FISolid, BookOpenIcon as BOISolid, DocumentCheckIcon as CBCISolid, GlobeAmericasIcon as GAISolid, WrenchIcon as WISolid, CurrencyDollarIcon, ChartBarIcon as CBSolid, MegaphoneIcon as MPSolid, CalendarDaysIcon as CISolid } from '@heroicons/react/24/solid';
import { AirplaneIcon, CalendarAddIcon, DistanceIcon } from '../../../assets/icons';
import BarTop from '../components/BarTop';
import BarSide from '../components/BarSide';
import MobileMenu from '../components/MobileMenu';
import MobileThumbBar from '../components/MobileThumbBar';
import { GetAnnouncementCountDocument, GetCraftsDocument } from 'graphql/generated';
import { useSession } from 'contexts';
import { useQuery } from '@apollo/client';
import { AbilityContext } from 'contexts/AbilityContext';
import { permissionHandler } from 'utils/PermissionHandler';

const Sidebar = (
  { children, navList, userNavList, }: {
    children: React.ReactNode;
    navList?: { name: string; href: string; icon: typeof Squares2X2Icon; roles?: string[], active?: boolean }[];
    userNavList?: { name: string; href: string }[];
  }) => {

  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [mobileMenu, setMobileMenu] = useState(false);
  const { craftId, organizationId, organizationType, organizationSubtype } = useSession();
  const [context, setContext] = useState(null);

  const { data } = useQuery(GetCraftsDocument, {
    variables: { organizationId },
  });

  const { data: { announcementCount } = {} } = useQuery( GetAnnouncementCountDocument, { variables: { where : { organizationId: { equals: organizationId } } } });
  useEffect(()=>{
    const craft = data?.crafts?.find((o) => o.id === craftId);
    if(craft){
      setContext({ line1: craft.tailNumber, line2: craft.name });
    }
    if(craftId==null){
      setContext(null);
    }
  }, [data, craftId]);

  const ability = useContext(AbilityContext);
  const isDev = process.env.REACT_APP_APP_MODE !== 'production';
  const navigation = navList || [
    { name: 'Aircraft', href: '/crafts', icon: Squares2X2Icon, iconActive: VGISolid, active: false },
    { name: context?.line1 ?? "", href: '/aircraft', icon: AirplaneIcon, iconActive: AirplaneIcon, active: true, hidden: context==null },
    { name: 'Status', href: '/aircraft/status', icon: DocumentCheckIcon, iconActive: CBCISolid, active: false, hidden: context==null,subMenu: true },
    ...(permissionHandler('Squawks', ability, organizationSubtype) ? [{ name: 'Squawks', href: '/aircraft/squawks', icon: FlagIcon, iconActive: FISolid, active: false, hidden: context==null, subMenu: true }] : []),
    ...(permissionHandler('Logbooks', ability, organizationSubtype) ? [{ name: 'Logbooks', href: '/aircraft/logbooks', icon: BookOpenIcon, iconActive: BOISolid, active: false, hidden: context==null, subMenu: true }] : []),
    ...(permissionHandler('Aircraft Documents', ability, organizationSubtype) ? [{ name: 'Documents', href: '/aircraft/documents', icon: BriefcaseIcon, iconActive: BISolid,  hidden: context==null, subMenu: true, active: false }] : []),
    ...(isDev && permissionHandler('Requests', ability, organizationSubtype) ? [{ name: 'Requests', href: '/aircraft/requests', icon: WrenchIcon, iconActive: WISolid, active: false, hidden: context==null, subMenu: true }] : []),
    ...(permissionHandler('Flights', ability) ? [{ name: 'Flights', href: '/flights', icon: GlobeAmericasIcon, iconActive: GAISolid, active: false }] : []),
    ...(permissionHandler('Reservations', ability) ? [{ name: 'Reservations', href: '/reservations', icon: CalendarDaysIcon, iconActive: CISolid, active: false }] : []),
    ...(permissionHandler('Reports', ability) ? [{ name: 'Reports', href: '/reports', icon: ChartBarIcon, iconActive: CBSolid, active: false}] : []),
    ...(permissionHandler('Payments', ability) ? [{ name: 'Payments', href: '/payments', icon: CurrencyDollarIcon, iconActive: CurrencyDollarIcon, active: false }] : []),
    ...(permissionHandler('Announcements', ability) ? [{ name: 'Announcements', href: '/announcements', icon: MegaphoneIcon, iconActive: MPSolid, active: false }] : []),
  ];
  const mobileNav = [
    { name: 'Aircraft', href: craftId? '/aircraft' : '/crafts', icon: AirplaneIcon, active: true, alt: !craftId ? '/aircraft' : '/crafts' },
    ...(permissionHandler('Flights', ability) ? [{ name: 'Flights', href: '/flights', icon: DistanceIcon, active: false }] : []),
    ...(permissionHandler('Reservations', ability) ? [{ name: 'Reservations', href: '/reservations', icon: CalendarAddIcon, active: false }] : []),
    ...(permissionHandler('Reports', ability) ? [{ name: 'Reports', href: '/reports', icon: ChartBarIcon, active: false }] : []),
    ...(permissionHandler('Payments', ability) ? [{ name: 'Payments', href: '/payments', icon: CurrencyDollarIcon, active: false }] : []),
    // { name: 'More', href: '/mro/manuals', icon: MoreVertIcon, active: false }, 
  ];

  return (
    <>
      {/* NAV */}
      <BarTop sidebarOpen={sidebarOpen} mobileMenu={mobileMenu} setMobileMenu={setMobileMenu} context={context} />
      <BarSide sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} navigation={navigation} />
      <MobileMenu navigation={navigation} mobileMenu={mobileMenu} />
      <MobileThumbBar mobileNav={mobileNav} />

      {/* CONTENT */}

      <div className={`${ sidebarOpen ? 'md:pl-72' : 'md:pl-8'} transition-all ease-in-out duration-300 relative w-full h-full pt-0 md:pt-10`}>
          {children}
      </div>

    </>
  );
};


export default Sidebar;
