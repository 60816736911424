import { Craft, Reservation } from "graphql/generated";
import { applyTimezone, formatApiDateTimeUTC, formatCalendarHour } from "utils/formatter";
import { areDaysEqual, getMinutePercentage } from "../utils/date";
import { useSession } from "contexts";

export const eventColors = [
    "bg-brand text-white", "bg-brand-electric text-white", "bg-brand-purple text-white", "bg-blue-300 text-white",
    "bg-amber-50 text-black", "bg-blue-100 text-black", "bg-lime-200 text-black",
    "bg-purple-300 text-black", "bg-teal-400 text-white", 
    "bg-stone-50 text-black", "bg-red-100 text-black", "bg-neutral-200 text-black",
    "bg-indigo-300 text-black", "bg-yellow-400 text-white",
    "bg-cyan-50 text-black", "bg-orange-100 text-black", "bg-zinc-200 text-black",
    "bg-slate-300 text-black", "bg-blue-400 text-white", "bg-red-500 text-white",
    "bg-teal-50 text-black", "bg-amber-100 text-black", "bg-green-200 text-black",
    "bg-cyan-300 text-black", "bg-indigo-400 text-white", "bg-purple-500 text-white",
    "bg-neutral-50 text-black", "bg-yellow-100 text-black",
    "bg-lime-300 text-black", "bg-green-400 text-black", "bg-orange-500 text-white",
    "bg-stone-50 text-black", "bg-yellow-200 text-black",
    "bg-indigo-300 text-black", "bg-red-400 text-white", "bg-cyan-500 text-white",
    "bg-gray-50 text-black", "bg-lime-100 text-black", "bg-amber-200 text-black",
    "bg-orange-300 text-black", "bg-blue-400 text-white", "bg-teal-500 text-white",
    "bg-slate-50 text-black", "bg-red-100 text-black",
    "bg-green-300 text-black", "bg-blue-400 text-white", "bg-yellow-500 text-white",
    "bg-neutral-50 text-black", "bg-orange-100 text-black", "bg-lime-200 text-black",
    "bg-purple-300 text-black", "bg-indigo-400 text-white", "bg-cyan-500 text-white",
    "bg-gray-50 text-black", "bg-stone-100 text-black", "bg-yellow-200 text-black",
    "bg-green-300 text-black", "bg-teal-400 text-white", "bg-red-500 text-white",
    "bg-indigo-50 text-black", "bg-yellow-100 text-black", "bg-amber-200 text-black",
    "bg-blue-300 text-black", "bg-orange-400 text-white", "bg-green-500 text-white",
    "bg-purple-50 text-black", "bg-zinc-100 text-black", "bg-teal-200 text-black",
    "bg-cyan-300 text-black", "bg-red-400 text-white", "bg-lime-500 text-white",
    "bg-stone-50 text-black", "bg-gray-100 text-black", "bg-purple-200 text-black",
    "bg-cyan-300 text-black", "bg-blue-400 text-white", "bg-yellow-500 text-white",
    "bg-neutral-50 text-black", "bg-green-100 text-black", "bg-lime-200 text-black",
    "bg-indigo-300 text-black", "bg-orange-400 text-white",
];

export enum TimeSlotsType {
    Week,
    Day
}

const dateConversion = (event: Reservation, field: string) =>
    new Date(formatApiDateTimeUTC(event[field]));


function TimeSlots({ data, events = [], onClick, type, crafts, userList, colorTiedToCraft }: { crafts?: Craft[], data: Array<Array<Date>>, events?: Array<any>, onClick: (any) => void, type: TimeSlotsType, userList?: Array<string>, colorTiedToCraft?: boolean }) {
    const { user } = useSession();
    let colorIndex = -1;
    const eventColorMap = {};

    // if colorTiedToCraft is true, then the color of the event will be tied to the craft
    // if colorTiedToCraft is true, then the color of the event will be tied to the craft
    if (colorTiedToCraft) {
        for (const craft of crafts) {
            colorIndex += 1;
            eventColorMap[craft.id] = eventColors[colorIndex];
        }
    }else{
        for (const userId of userList) {
            colorIndex += 1;
            eventColorMap[userId] = eventColors[colorIndex];
        }
    }

    

    return (
        <div className="flex rounded-b border-b w-full border-r border-l border-slate-300">
            {data.map((col, colI) => { 
            var didShowTextOnCol = [];
            return (
                <div
                    className={`flex flex-col ${colI === 0 ? "flex-1" : "min-w-[100px] flex-[1.7]"}`}
                    key={colI}
                >
                    {col.map((row) => {
                        const showTime = colI === 0 && row.getMinutes() === 0;
                        
                        return (
                            <div className="border flex h-[26px]" key={row.toISOString()}>
                                {colI === 0 ? (
                                    <div className="flex w-full">{showTime ? formatCalendarHour(row) : "\xa0"}</div>
                                ) : (
                                    events.map((event: any, eventI) => {
                                        const eventRes = event as Reservation;
                                        
                                        

                                        let [percentShow, rangeType] = getMinutePercentage(
                                            row,
                                            dateConversion(event, 'from'),
                                            dateConversion(event, 'to')
                                        );
                                        if(!eventRes.allDay){
                                            if (
                                                (type === TimeSlotsType.Day && crafts && crafts[colI - 1].id !== eventRes.craftId) ||
                                                (type === TimeSlotsType.Week && percentShow === 0 && 
                                                (areDaysEqual(row, dateConversion(event, 'to')) || areDaysEqual(row, dateConversion(event, 'from'))))
                                            ) {
                                                return null;
                                            }

                                            if (percentShow === 0) return null;
                                        }else{
                                            // Check if the day is between the from and to dates
                                            const d = applyTimezone(row, 0, Number(user?.timezone)).toISOString().split('T')[0];
                                            const start = new Date(dateConversion(event, 'from')).toISOString().split('T')[0];
                                            const end = new Date(dateConversion(event, 'to')).toISOString().split('T')[0];

                                            if(d >= start && d <= end){
                                                percentShow = 100;
                                            } else{
                                                return null;
                                            }

                                        }

                                        const colorId = colorTiedToCraft ? eventRes.craftId : eventRes.userId;
                                        const percentStyle = `h-[${String(percentShow + 1).padStart(2, '0')}]%`;
                                        let buttonClassName = `${eventRes.isMaintenance ? 'flex truncate bg-slate-500 text-white' : eventColorMap[colorId]} ${rangeType === "from" && percentShow !== 100 ? "mb-[-2px]" : "my-[-2px]"} py-3 w-full ${percentStyle}`;
                                        let textForButton = "\xa0";

                                        if (percentShow === 100 && !didShowTextOnCol[event.id] && row.getHours() < 22) {
                                            didShowTextOnCol[event.id] = true;
                                            textForButton = event.name;
                                            buttonClassName += ` z-10 text-xs break-words hover:overflow-visible hover:z-50 hover:text-black`;
                                        }

                                        return (
                                            <button onClick={() => onClick(event)} className={buttonClassName} key={event.id}>
                                                {textForButton}
                                            </button>
                                        );
                                    })
                                )}
                            </div>
                        );
                    })}
                </div>
            )})}
        </div>
    );
}


export default TimeSlots;