import { useNavigate } from "react-router-dom";
import { BookOpenIcon, DocumentCheckIcon, WrenchIcon, BriefcaseIcon, FlagIcon } from '@heroicons/react/24/outline';
import { CaretRightIcon } from '../../../assets/icons';
import { PencilIcon } from '@heroicons/react/24/outline';
import { useSession } from "contexts/SessionContext";
import { useQuery } from "@apollo/client";
import { GetCraftDocument,LogType, Severity, Status } from "graphql/generated";
import Flyout,{ useFlyout } from "components/Flyout/Flyout";
import EditAircraft from "./Edit";
import { Fragment, useContext, useEffect } from "react";
import Button from "components/Button/Button";
import { applyTimezone, formatApiDateTime } from "utils/formatter";
import { AbilityContext } from "contexts/AbilityContext";
import { permissionHandler } from "utils/PermissionHandler";
import { webFormat } from "utils/statuses";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import ShareAircraft from "./Share";
import { OrgSubtypes } from "utils/orgTypes";
import Intervals from "./Intervals";
import { WalkthroughContext } from "../Walkthrough";

const Aircraft = function() {
  const navigate = useNavigate();
  const { craftId, organizationSubtype, user } = useSession();
  const { formStep, changeStep } = useContext(WalkthroughContext);
  const { flyoutProps, updateFlyout, closeFlyout } = useFlyout();
  const { loading, error, data : { craft } = {} } = useQuery(GetCraftDocument, { variables: { craftId, squawksWhere: { status: { not: { in: [Status.Resolved] } } } }, fetchPolicy: 'network-only' });
  const ability = useContext(AbilityContext);
  useEffect(() => {    
    if(!craftId){
      navigate('/crafts');
    }
  }, [craftId]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error.toString()}</p>;
  
  const isDev = process.env.REACT_APP_APP_MODE !== 'production';
  const listContent = [
    { name: 'Status', href: '/aircraft/status', icon: DocumentCheckIcon, badges: { warning: craft.inspections.filter(ins => ins.almostDue).length, critical: craft.inspections.filter(ins => ins.due).length } },
    ...(permissionHandler( 'Squawks', ability, organizationSubtype) ? [{ name: 'Squawks', href: '/aircraft/squawks', icon: FlagIcon, badges: { warning: craft.squawks.filter((squawk)=>{return squawk.severity == Severity.Warning}).length, critical:craft.squawks.filter((squawk)=>{return squawk.severity == Severity.Grounded}).length }}] : []),
    ...(permissionHandler( 'Logbooks', ability, organizationSubtype) ? [{ name: 'Logbooks', href: '/aircraft/logbooks', icon: BookOpenIcon, badges: {} }] : [] ),
    ...(permissionHandler( 'Aircraft Documents', ability, organizationSubtype) ? [{ name: 'Documents', href: '/aircraft/documents', icon: BriefcaseIcon, badges: {} }] : []),
    ...(isDev && permissionHandler( 'Requests', ability) ? [{ name: 'Requests', href: '/aircraft/requests', icon: WrenchIcon, badges: {} }] : []),
  ];
  const firstName = craft?.nextReservation?.pilot?.firstName ?? '';
  const lastName = craft?.nextReservation?.pilot?.lastName ?? '';
  const from = craft?.nextReservation?.from ?? '';


  let boxNumber = 3;
  if( organizationSubtype !== OrgSubtypes.FREE && permissionHandler( 'Payments', ability, organizationSubtype)){
    boxNumber +=1;
  }
  if(craft?.loggingOption == LogType.Logboth){
    boxNumber +=1;
  }
  if(organizationSubtype !== OrgSubtypes.MULTI){
    boxNumber -=1;
  }
  const statusBoxes = `w-1/2 p-1 pr-1 lg:pr-2 lg:last:pr-0 lg:w-1/${boxNumber}`;
  
  let hobbsValue = Number(craft?.intervals.find((i) => i.name.includes("Hobbs"))?.recentIntervalLog?.endValue);
  hobbsValue = !Number.isInteger(hobbsValue) ? parseFloat(hobbsValue.toFixed(2)) : hobbsValue;

  let tachValue = Number(craft?.intervals.find((i) => i.name.includes("Tach"))?.recentIntervalLog?.endValue);
  tachValue = !Number.isInteger(tachValue) ? parseFloat(tachValue.toFixed(2)) : tachValue;
  return(
    <>
      {/* Flyout */}
      <Flyout {...flyoutProps} />
      <div className="flex flex-col w-full">
        <div className="flex flex-col w-full">
          {/* Header */}
          <div className="flex justify-between items-center bg-white p-6 border border-slate-300 rounded mb-2 shadow-blue">
            <div className="flex items-center">
              <DocumentCheckIcon className="h-8 w-8 bg-brand-pale mr-2 text-brand-electric rounded p-[0.375rem]" />
              <div className="flex flex-col">
                <h1 className="text-left font-bold text-brand-dark text-2xl md:text-2xl">{craft?.tailNumber}</h1>
                <h1 className="text-left font-bold text-brand-dark">{ craft.make ? craft?.name : ""}</h1>
              </div>
            </div>
            <div className="flex items-stretch gap-2">
              <Button text="Edit Aircraft" color="navy" size="xs" icon={<PencilIcon className="h-3 w-3 mr-1" />}
                onClick={ () => updateFlyout({ title: 'Edit Aircraft', content: <EditAircraft closeFlyout={closeFlyout} /> }) } />
               { permissionHandler( 'Manage Intervals', ability, organizationSubtype) && <Menu
                as="div"
                className="flex items-center justify-center relative group transition ml-2 w-[28px] border border-white rounded hover:border-slate-200 hover:shadow-inner">
                <Menu.Button className="">
                  <EllipsisVerticalIcon className="h-5 w-5 text-slate-500 cursor-pointer" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95">
                  <Menu.Items className="origin-top-right absolute top-full right-0 mt-2 rounded shadow-blue-lg border border-slate-300 bg-white focus:outline-none z-[99] w-36">
                    <Menu.Item>
                    {({ active }) => (
                      <button 
                      onClick={() => updateFlyout({ title: 'Intervals', content: <Intervals /> })}
                      className={`${active ? 'bg-slate-100' : ''} block px-4 py-3 text-sm text-slate-700 w-full text-right rounded`}>
                        Intervals
                      </button>
                    )}
                    </Menu.Item> 
                    {/* <Menu.Item>
                      {({ active }) => (
                        <button 
                        onClick={() => updateFlyout({ title: 'Share Aircraft', content: <ShareAircraft closeFlyout={closeFlyout} /> })}
                        className={`${active ? 'bg-slate-100' : ''} block px-4 py-3 text-sm text-slate-700 w-full text-right rounded`}>
                          Share
                        </button>
                      )}
                    </Menu.Item> */}
                  </Menu.Items>
                </Transition>
              </Menu>}
            </div>
          </div>
        </div>
        {/* Dashboard */}
        <div className="flex flex-row items-center justify-between flex-wrap px-1 mb-2">
            <div className={statusBoxes}>
              <div className={`flex flex-col justify-center items-center text-white ${craft?.isAirworthy ? 'bg-brand' : 'bg-red-500' } py-2 rounded`}>
                <div className="flex flex-col justify-center items-center h-20">
                  <span className="">Aircraft Status</span>
                  <span className="font-bold">{ craft?.isAirworthy ? "Ready" : "Grounded" }</span>
                </div>
              </div>
            </div>
            {organizationSubtype === OrgSubtypes.MULTI && permissionHandler( 'Payments', ability, organizationSubtype) && <div className={statusBoxes}>
               <div onClick={() => navigate('/reservations')} className={`cursor-pointer flex flex-col justify-center items-center text-white ${craft?.currentReservation ? 'bg-brand-dark' : 'bg-brand' } py-2 rounded`}>
                <div className="flex flex-col justify-center items-center h-20">
                  <span className="">Schedule Status</span>
                  <span className="font-bold">{ craft?.currentReservation ? "Booked" : "Available Now" }</span>
                  {craft?.currentReservation && <span className="text-sm">Till {formatApiDateTime(craft?.currentReservation?.to)}</span> }
                </div>
              </div>
            </div>}
            {organizationSubtype !== OrgSubtypes.FREE && <div className={statusBoxes}>
              <div className="flex justify-center items-center text-white bg-brand-electric py-2 rounded">
                <div className="flex flex-col justify-center items-center h-20">
                  <span className="text-sm">Fuel Status</span>
                  <span className="font-bold">{craft?.fuelAmount} { craft?.fuelAmount !== 'Unknown' && webFormat(craft.fuelUnit) }</span>
                </div>
              </div>
            </div> }
            {craft?.loggingOption !== LogType.Logtach && <div className={statusBoxes}>
              <div className="flex flex-col justify-center items-center text-white bg-brand py-2 rounded">
                <div className="flex flex-col justify-center items-center h-20">
                  <span className="text-sm">Hobbs</span>
                  <span className="font-bold">{ hobbsValue ?? 0 }</span>
                </div>
              </div>
            </div> }
            {craft?.loggingOption !== LogType.Loghobbs && <div className={statusBoxes}>
              <div className="flex flex-col justify-center items-center text-white bg-brand-dark py-2 rounded">
                <div className="flex flex-col justify-center items-center h-20">
                  <span className="text-sm">Tach</span>
                  <span className="font-bold">{ tachValue ?? 0 }</span>
                </div>
              </div>
            </div> }
        </div>
        <div className="bg-white border border-slate-300 rounded mb-2">
          {/* Reservation Banner */}
          {craft?.nextReservation && <button 
            onClick={() => navigate('/reservations')}
            className="flex justify-between items-center w-full h-10 px-4 md:px-8 my-4 bg-brand-pale">
            <span className="font-extrabold text-xs text-brand underline">Next Reservation: {`${firstName} ${lastName} ${formatApiDateTime(applyTimezone(from, Number(user.timezone)).toISOString())}`}</span>
            <CaretRightIcon className="h-4 w-4 ml-1" />
          </button> }
          {/* Link List */}
          <div className="flex flex-col w-full">
            {listContent.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => { 
                    if(item.name === "Status" && formStep === "selectInspections") changeStep('selectInspections', 'inspectionsPageOverview', false);
                    navigate(item.href);
                  }}
                  className={`flex group first:rounded-t last:rounded-b justify-between items-center w-full border-b last:border-b-0 p-4 md:px-6 md:hover:pl-8 cursor-pointer bg-white hover:bg-slate-50 transition ${item.name === "Status" && formStep === "selectInspections" && "z-[102]"}`}>
                  <div className="flex w-1/2 items-center">
                    <item.icon className="h-8 w-8 p-[0.375rem] text-brand-electric bg-brand-pale rounded group-hover:bg-brand-electric group-hover:text-white transition" />
                    <span className="font-bold text-xl mx-2">{item.name}</span>
                  </div>
                  <div className="flex items-center">
                    <div className="pr-4 flex">
                      {/* {!!item.badges.total && (
                        <span className="flex items-center justify-center h-8 w-8 p-2 ml-2 rounded font-bold bg-gray-100 text-black">
                          {item.badges.total}
                        </span>
                      )} */}
                      {!!item.badges.warning && (
                        <span className="flex items-center justify-center h-8 w-8 p-2 ml-2 rounded font-bold bg-yellow-100 text-yellow-500">
                          {item.badges.warning}
                        </span>
                      )}
                      { !!item.badges.critical && (
                        <span className="flex items-center justify-center h-8 w-8 p-2 ml-2 rounded font-bold bg-red-100 text-red-500">
                          {item.badges.critical}
                        </span>
                      )}
                    </div>
                    <CaretRightIcon className="h-4 w-4 ml-1" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Aircraft;
