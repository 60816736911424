import { Fragment, useState } from 'react';
import { EventConfirmIcon, CaretRightIcon, DocumentIcon, GearIcon, PlusIcon } from 'assets/icons';
import { BookmarkSquareIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@apollo/client';
import { formatApiDate } from 'utils/formatter';
import Flyout, { useFlyout } from 'components/Flyout/Flyout';
import Edit from './Edit';
import { default as NewEntry } from './Entries/New';
import New from './New';
import { Link } from 'react-router-dom';
import { useSession } from 'contexts/SessionContext';
import { GetLogbooksDocument } from 'graphql/generated';
import { BookOpenIcon, EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import Button from 'components/Button/Button';
import Paywall from 'components/Paywall/Paywall';
import { Menu, Transition } from '@headlessui/react';
import isMobileApp from 'utils/isMobileApp';

const Logbooks = function () {
  const { craftId, organizationSubtype } = useSession();
  const { data } = useQuery(GetLogbooksDocument, { variables: { craftId } });
  const { flyoutProps, updateFlyout, closeFlyout } = useFlyout();
  const listLogbooks = data?.logBooks;

  return (
    <>
      <Flyout {...flyoutProps} />
      {/* Header */}
      <div>
        <div className="flex-rows items-center bg-white border border-slate-300 rounded shadow-blue mb-2">
          <div className="flex justify-between items-center p-6">
            <div className="flex items-center">
              <BookOpenIcon className="h-8 w-8 bg-brand-pale mr-2 text-brand-electric rounded p-[0.375rem]" />
              <h1 className="text-left font-bold text-brand-dark text-2xl md:text-2xl">Logbooks</h1>
            </div>
            {organizationSubtype !== 'FREE' && <div className="flex items-stretch gap-2">
              <Button text="Add Logbook" color="navy" size="xs" icon={<BookmarkSquareIcon className="h-3 w-3 mr-1 stroke-[0.15rem]" />}
                onClick={ () => updateFlyout({ title: 'Add Logbook', content: <New closeFlyout={closeFlyout}/> }) } />
            </div> }
            {/* <Menu
              as="div"
              className="flex items-center justify-center relative group transition ml-2 w-[28px] border border-white rounded hover:border-slate-200 hover:shadow-inner">
              <Menu.Button className="">
                <EllipsisVerticalIcon className="h-5 w-5 text-slate-500 cursor-pointer" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95">
                <Menu.Items className="origin-top-right absolute top-full right-0 mt-2 rounded shadow-blue-lg border border-slate-300 bg-white focus:outline-none z-[99] w-36">
                  <Menu.Item>
                    {({ active }) => (
                      <button 
                      onClick={() => updateFlyout({ title: 'Import ADS', content: <ADSFlyout closeFlyout={closeFlyout} /> })}
                      className={`${active ? 'bg-slate-100' : ''} block px-4 py-3 text-sm text-slate-700 w-full text-right rounded`}>
                        Import ADS from FAA
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      isMobileApp() ?
                      <button onClick={async () => { await navigator.share({ title:"Export Inspections from Coflyt", url: process.env.REACT_APP_API_URL+"/inspections/"+craftId, text: "Export Inspections from Coflyt"}) } } className='block px-4 py-3 text-sm text-slate-700 w-full text-right rounded'>Export</button>
                      :
                      <a
                      href={process.env.REACT_APP_API_URL+"/inspections/"+craftId}
                      className={`${active ? 'bg-slate-100' : ''} block px-4 py-3 text-sm text-slate-700 w-full text-right rounded`}
                      download>
                        Export Inspections
                      </a>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu> */}
          </div>
        </div>
        {organizationSubtype === 'FREE' ? <Paywall /> :
          <div className="flex flex-wrap gap-2 w-full">
              {listLogbooks?.length ?
                listLogbooks.map((book, index) => {
                  return (
                    <div key={book.id} className={`w-full md:w-[calc(50%-0.25rem)]`}>
                      <div className="flex flex-col bg-white md:rounded border border-slate-300">
                        <Link to={`/aircraft/logbooks/${book.id}`} className="flex items-center justify-between p-4">
                          <div className="flex items-center">
                            <EventConfirmIcon className="h-12 w-12 p-3 mr-3 text-brand-electric bg-brand-pale rounded border border-brand-electric" />
                            <div className="flex flex-col">
                              <span className="font-bold text-brand-dark">{book.name ?? ''}</span>
                              <span className="text-xs -mt-1">Last Entry: {formatApiDate(book.lastEntry?.logDate) || 'N/A'}</span>
                            </div>
                          </div>
                          <CaretRightIcon className="h-4 w-4 ml-1" />
                        </Link>
                        <div className="border-t px-4 py-2 flex flex-row-reverse items-center justify-start text-sm bg-slate-50 rounded-b">
                          <button
                            className="flex items-center justify-center font-bold bg-white hover:text-white hover:bg-brand-electric transition text-brand-electric border-brand-electric border py-1 px-2 rounded grow"
                            onClick={() => {
                              updateFlyout({ title: `New Entry - ${book.name}`, content: <NewEntry closeFlyout={closeFlyout} id={book?.id} /> });
                            }}>
                            <DocumentIcon className="h-3 w-3 mr-1" /> New Entry
                          </button>
                          <button
                            className="flex items-center justify-center font-bold bg-white hover:text-white hover:bg-brand-electric transition text-brand-electric border-brand-electric border py-1 px-2 rounded mx-1 grow"
                            onClick={() => {
                              updateFlyout({ title: book.name, content: <Edit closeFlyout={closeFlyout} id={book.id} /> });
                            }}>
                            <GearIcon className="h-3 w-3 mr-1" /> Edit
                          </button>
                          <span className="font-semibold text-center border py-1 px-2 rounded grow bg-white">{book.totalEntries} Total Entries</span>
                        </div>
                      </div>
                    </div>
                  );
                }) : <div className="w-full text-center justify-center flex flex-col gap-5 pt-10">
                  <span className='text-2xl font-bold'>No logbooks found</span>  
                  <Button text="+ Add First Logbook" color="navy" size="sm"
                    onClick={ () => updateFlyout({ title: 'Add Logbook', content: <New closeFlyout={closeFlyout}/> }) } />
                </div>}
          </div>
        }
      </div>
    </>
  );
};

export default Logbooks;
