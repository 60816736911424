import React, { useState, useEffect, useCallback } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';

// Expected Data Structure
/* 
props: {
  isOpen: bool,                                                                         // open/closed state
  data: {
    title: string,                                                                      // name of window
    content: JSX                                                                        // component from components/Flyout/Content
  },
}
*/

export interface FlyoutProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  data: {
    title: JSX.Element | string;
    content: JSX.Element;
    icon?: JSX.Element;
  };
}

export interface FlyoutHookReturn {
  flyoutProps: FlyoutProps;
  activeFlyout: FlyoutProps['data'];
  setActiveFlyout: React.Dispatch<React.SetStateAction<FlyoutProps['data']>>;
  updateFlyout: (data?: React.SetStateAction<FlyoutProps['data']>) => void;
  closeFlyout: (data?: React.SetStateAction<FlyoutProps['data']>) => void;
}

export const useFlyout = ({ isOpen: isOpenInit = false, data: { title = '', content = <></> } = {} } = {}): FlyoutHookReturn => {
  const [isOpen, setIsOpen] = useState(isOpenInit);
  const [activeFlyout, setActiveFlyout] = useState<FlyoutProps['data']>({ title: title, content: content });

  const updateFlyout = useCallback((data?: React.SetStateAction<FlyoutProps['data']>) => {
    if (data) setActiveFlyout(data);
    setIsOpen(true);
  }, []);

  const closeFlyout = useCallback((data?: React.SetStateAction<FlyoutProps['data']>) => {
    if (data) setActiveFlyout(data);
    setIsOpen(false);
  }, []);

  return {
    flyoutProps: { isOpen, setIsOpen, data: activeFlyout },
    activeFlyout,
    setActiveFlyout,
    updateFlyout,
    closeFlyout,
  };
};

export function Flyout({ setIsOpen, ...props }: FlyoutProps) {
  let content = props.isOpen ? props.data.content : <></>;

  const handleEscape = useCallback(
    function (e) {
      if (e.key === 'Escape') {
        setIsOpen(false);
      }
    },
    [setIsOpen]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleEscape, true);
  }, [handleEscape]);

  return (
    <div>
      <div
        className={`${
          props.isOpen ? '' : 'translate-x-full'
        } w-full md:w-[55%] md:max-w-screen-md z-[59] transition-all ease-in-out fixed -mt-4 right-0 top-4 h-screen sm:p-4`}>
        <div className="bg-[#FAFAFD] shadow-2xl shadow-brand-dark/40 border border-slate-300 sm:rounded-lg h-full overflow-auto">
          <div className="sticky top-0 z-10 w-full p-4 flex bg-white text-brand items-center justify-between border-b border-slate-200 rounded-t-lg">
            <div className="flex items-center">
              {props.data.icon}
              <span className="text-lg font-bold">{props.data.title}</span>
            </div>
            <XMarkIcon className="h-6 w-6 cursor-pointer" onClick={() => setIsOpen(false)} />
          </div>
          {content}
        </div>
      </div>
      {props.isOpen && (
        <div className="flex items-center justify-center fixed h-screen w-screen top-0 left-0 z-40">
          <div
            onClick={() => {
              setIsOpen(false);
            }}
            className="bg-slate-100/20 backdrop-blur-[2px] w-full h-full absolute top-0 left-0 transition-all"></div>
        </div>
      )}
    </div>
  );
}

export default Flyout;
