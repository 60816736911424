import React from 'react';

export default function Card( { children, title, className = '', titleSize = 'xs', subtitle } 
: 
{ children: React.ReactNode; title?: string, className?:string, titleSize?:string, subtitle?:string } ){
    return (
        <div className={"bg-white rounded shadow border border-slate-200 p-6 mt-3 "+className}>
            { title && <>
                <div className='flex flex-col'>
                    <span className={`font-bold text-${titleSize} text-brand-dark uppercase py-0.5 -mx-4 pl-4`}>{title}</span>
                    {subtitle && <span className={`font-semibold text-xs text-brand-dark py-0.5 -mx-4 pl-4`}>{subtitle}</span> }
                </div>
                <hr className="mt-2 mb-4 -m-6" />
            </> }
            {children}
        </div>
    )
}
