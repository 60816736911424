function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}

export type Interval = {
    id?: string;
    name: string;
    startValue: number;
    endValue: number;
    optional?: boolean;
    isCustom?: boolean;
};

export default function IntervalItem(
{   interval, 
    index, 
    required = false,
    handleItemUpdate,
    isZeroNull = false,
    error
} : {
    interval: Interval;
    required?: boolean;
    index: number;
    error?: any;
    isZeroNull?: boolean;
    handleItemUpdate: (id: string, type : string, value: string) => void;
}) {
    return( 
      <div className="flex w-full gap-2 mb-6 last:mb-3">
        {/* <span className="w-1/3 self-center text-sm sm:text-md">{interval?.name}{required && '*'}</span> */}
        <div className="flex flex-col w-1/2">
            <div className="">
              <label className="text-sm font-medium text-brand">{interval?.name} Out {required && '*'}</label>
              <input onChange={(e)=> handleItemUpdate( interval.id, 'startValue', e.target.value ) } value={interval?.startValue === 0  && isZeroNull ? '' : interval?.startValue} className="w-full h-12 md:h-10 flex-1 rounded bg-slate-50 border-slate-300 border px-2 text-brand focus:outline-0 focus:bg-white placeholder:text-slate-400 placeholder:text-sm" />
            </div>
            <span className="text-red-500 text-xs mx-2 mt-1 mb-[-20px]">{error?.startValue ?? '\xa0'}</span>
        </div>
        <div className="flex flex-col w-1/2">
            <div className="">
              <label className="text-sm font-medium text-brand">{interval?.name} In {required && '*'}</label>
              <input onChange={(e)=> handleItemUpdate( interval.id, 'endValue', e.target.value ) } value={interval?.endValue === 0  && isZeroNull ? '' : interval?.endValue} className="w-full h-12 md:h-10 flex-1 rounded bg-slate-50 border-slate-300 border px-2 text-brand focus:outline-0 focus:bg-white placeholder:text-slate-400 placeholder:text-sm" />
            </div>
            <span className=" text-red-500 text-xs mx-2 mt-1 mb-[-20px]">{error?.endValue ?? '\xa0'}</span>
        </div>
      </div> 
    );
    
}