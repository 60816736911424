/* eslint-disable react/jsx-key */
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import Button from 'components/Button/Button';
import Card from 'components/Card/Card';
import { useSession } from 'contexts';
import { SortOrder } from 'graphql/generated';
import React, { ComponentPropsWithRef, forwardRef, Fragment, useEffect } from 'react';
import { useTable, useSortBy, Column, useGlobalFilter, useExpanded, usePagination } from 'react-table';
// HEAD
const Thead = forwardRef<HTMLTableSectionElement, ComponentPropsWithRef<'thead'>>(function Thead({ className, children, ...props }, ref) {
  return (
    <thead ref={ref} className={className} {...props}>
      {children}
    </thead>
  );
});
const Th = forwardRef<HTMLTableCellElement, ComponentPropsWithRef<'th'>>(function Th({ className, children, ...props }, ref) {
  return (
    <th
      ref={ref}
      className={'flex items-center p-4 text-brand-dark bg-white first:rounded-tl last:rounded-tr font-bold ' + className}
      {...props}>
      {children}
    </th>
  );
});
// BODY
const Tbody = forwardRef<HTMLTableSectionElement, ComponentPropsWithRef<'tbody'>>(function Tbody(props, ref) {
  return <tbody ref={ref} {...props}></tbody>;
});
const Tr = forwardRef<HTMLTableRowElement, { index?: number } & ComponentPropsWithRef<'tr'>>(function Tr(
  { index = 0, className, children, ...props },
  ref
) {
  const data = children?.[0]?.props?.children?.props?.data?.[index];
  const isLocked = data?.locked === true;
  return (
    <tr ref={ref} className={className + ` flex ${!isLocked ? 'hover:bg-brand-offwhite hover:cursor-pointer' : ''} group transition`} {...props}>
      {children}
    </tr>
  );
});
const Td = forwardRef<HTMLTableCellElement, { primary?: boolean } & ComponentPropsWithRef<'td'>>(function Td(
  { children, primary = false, className, ...props },
  ref
) {
  return (
    <td ref={ref} className={'flex items-center border-t border-slate-200 group-hover:border-brand-pale p-4 py-2 ' + className} {...props}>
      {children}
    </td>
  );
});

export const Table = React.memo(
  forwardRef<
    HTMLTableElement,
    {
      columns?: any[];
      data?: any[];
      onRowClick?: Function;
      context?: any;
      handleClick?: any;
      taskColumns?: any;
      userId?: any;
      showAll?: any;
      limit?: any;
      skip?: any;
      count?: any;
      updateLimitAndSkip?: any;
      setSortBy?: any;
      mobileRowCount?: any;
    } & React.ComponentPropsWithRef<'table'>
  >(function Table({ columns = [], data = [], children, onRowClick, handleClick, taskColumns, userId, showAll, limit = 1, skip = 0, count = 0, setSortBy = () => {}, updateLimitAndSkip = () => {}, mobileRowCount = 0, ...props }, ref) {
    
    const tableInstance = useTable({ columns, data, autoResetSortBy: false }, useGlobalFilter, useSortBy, useExpanded);
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

    const pageCount = Math.ceil(count / limit);
    const pageIndex = Math.floor(skip / limit) + 1;
    const canGoForward = pageIndex < pageCount;
    const canGoBackward = pageIndex > 1;
    

    const handleColumnClick = (column) => {
      // Only sort on columns with a valid id and lowercase first letter
      if (typeof column?.id === 'string' && !/^[A-Z]/.test(column?.id)) {
        setSortBy([column.id, SortOrder.Desc]);
      }
    };
    
    return (
      <div className='w-full'>
        <div className="w-full overflow-x-auto rounded text-sm">
          <table ref={ref} className="min-w-max w-full" {...getTableProps()} {...props}>
            {/* HEAD */}
            <Thead className="uppercase sticky top-0">
              {headerGroups.map((headerGroup) => (
                <Tr {...headerGroup.getHeaderGroupProps()} className="">
                  {mobileRowCount ? <Th className="block sm:hidden w-6"/> : <></>}
                  {headerGroup.headers.map((column, idx) => (
                    <Th {...column.getHeaderProps([{ className: (column.className ?? ' ')+` ${ idx >= mobileRowCount && mobileRowCount && "hidden sm:flex" }` }, column.getSortByToggleProps()])} onClickCapture={() => handleColumnClick(column)} >
                      {column.render('Header')}
                      <span className="">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <ChevronUpIcon className="ml-2 h-4 w-4" />
                          ) : (
                            <ChevronDownIcon className="ml-2 w-4 h-4" />
                          )
                        ) : (
                          ''
                        )}
                      </span>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            {/* BODY */}
            <Tbody {...getTableBodyProps()}>
              {rows.map((row, index) => {
                const taskTableData = (row.original as any).mroWorkOrderItems?.map((j) => {
                  if (j.assignees?.find((e) => e.id === userId)) {
                    return { ...j, tailNumber: (row.original as any).mroCraft?.tailNumber, unowned: false, showAll: showAll };
                  }
                  return { ...j, tailNumber: (row.original as any).mroCraft?.tailNumber, unowned: true, showAll: showAll };
                });
                prepareRow(row);
                return (
                  <Fragment key={row.getRowProps().key}>
                    <Tr
                      {...row.getRowProps()}
                      index={index}
                      onClick={() => {
                        if (props.context === 'dashboard') {
                          return onRowClick(row);
                        }
                        onRowClick && onRowClick(row.original);
                      }}
                      className={`${row.isExpanded ? 'bg-brand-offwhite border-brand-pale' : 'border-white'} border-y transition-all`}>
                      {mobileRowCount ? <Td className="block sm:hidden" onClick={(e) => e.stopPropagation()}>
                        <span className="flex items-center" {...row.getToggleRowExpandedProps()}>
                          {row.isExpanded ? (
                            <ChevronDownIcon className="h-5 w-5 text-slate-500 hover:text-brand-electric" />
                          ) : (
                            <ChevronRightIcon className="h-5 w-5 text-slate-500 hover:text-brand-electric" />
                          )}
                        </span>  
                      </Td> : <></>}
                      {row.cells.map((cell, idx) => {
                        return <Td {...cell.getCellProps([{ className: (cell.column.className ?? ' ')+` ${ idx >= mobileRowCount && mobileRowCount && "hidden sm:flex" }` }])}>{cell.render('Cell')}</Td>;
                      })}
                    </Tr>
                    {row.isExpanded && props.context === 'dashboard' ? (
                      <tr className="">
                        <td className={`${row.isExpanded && 'pb-2'}`}>
                          {taskTableData.length > 0 ? (
                            <TaskTable
                              columns={taskColumns}
                              data={taskTableData}
                              className="no-head w-full"
                              context="dashboard"
                              onRowClick={(subrow) => {
                                handleClick(subrow.original);
                              }}
                            />
                          ) : (
                            <div className="bg-brand-offwhite border border-brand-pale rounded p-2 font-semibold text-brand text-center text-sm mt-2 mx-4">
                              No discrepancies on this work order.
                            </div>
                          )}
                        </td>
                      </tr>
                    ) : (
                      <></>
                    )}
                    {row.isExpanded && props.context !== 'dashboard' ? (
                      <Card className='mt-[-3px] block sm:hidden'>
                        { row.cells.slice(mobileRowCount).map((cell, idx) => {
                          return (
                            <div>
                              <div className="flex justify-between items-center p-2 border-b border-slate-200">
                                <div className="flex items-center">
                                  <span className="font-semibold">{cell.column.Header}:</span>
                                </div>
                                <div className="flex items-center">
                                  <span>{cell.render('Cell')}</span>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </Card>
                    ) : (
                      <></>
                    )}
                  </Fragment>
                );
              })}
            </Tbody>
          </table>
        </div>
        { count !== 0 && <div className="pagination w-full mt-4" >
          <div className='flex justify-center gap-5 flex-wrap'>
            <div className='flex gap-2'>
              <Button text="<<" onClick={() => updateLimitAndSkip(limit, 0)} disabled={!canGoBackward} color="navy" size="xs" />
              <Button text="<" onClick={() => updateLimitAndSkip(limit, skip-limit)} disabled={!canGoBackward} color="navy" size="xs" />
            </div>
            <div className="flex items-center order-last sm:order-none">
              <span className='mr-10'>
                Page{' '}
                {pageIndex} of {pageCount}
              </span>
              <select
                value={limit}
                onChange={e => updateLimitAndSkip(Number(e.target.value)) }
              >
                {[5, 10, 20, 30, 40, 50].map(ps => (
                  <option key={ps} value={ps}>
                    Show {ps}
                  </option>
                ))}
              </select>
            </div>
            <div className='flex gap-2'>
              <Button text=">"
                      onClick={() => updateLimitAndSkip(limit, skip+limit)} 
                      color="navy"
                      disabled={!canGoForward}
                      size="xs" />
              <Button text=">>"
                      onClick={() => updateLimitAndSkip(limit, (pageCount - 1) * limit)} 
                      color="navy"
                      disabled={!canGoForward}
                      size="xs" />
            </div>
          </div>
        </div> }
      </div>
    );
  })
);

export const TaskTable = React.memo(
  forwardRef<
    HTMLTableElement,
    { columns?: Column<object>[]; data?: any[]; onRowClick?: Function; context?: any } & React.ComponentPropsWithRef<'table'>
  >(function TaskTable({ columns = [], data = [], children, onRowClick, ...props }, ref) {
    const tableInstance = useTable({ columns, data }, useGlobalFilter, useSortBy);
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;
    const { selectedTask } = useSession();
    return (
      <div className="w-full overflow-x-auto rounded text-sm">
        <table ref={ref} className="w-full flex flex-col" {...getTableProps()} {...props}>
          {/* HEAD */}
          <Thead className="uppercase">
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()} className="">
                {headerGroup.headers.map((column) => (
                  <Th {...column.getHeaderProps([{ className: column.className ?? ' ' }, column.getSortByToggleProps()])}>
                    {column.render('Header')}
                    <span className="">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ChevronUpIcon className="ml-2 h-4 w-4" />
                        ) : (
                          <ChevronDownIcon className="ml-2 w-4 h-4" />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          {/* BODY */}
          <Tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              const isSelected = (row.original as any).id === selectedTask?.id;
              return (
                <Fragment key={row.getRowProps().key}>
                  <Tr
                    {...row.getRowProps()}
                    index={index}
                    onClick={() => {
                      if (props.context === 'dashboard') {
                        return onRowClick(row);
                      }
                      onRowClick && onRowClick(row.original);
                    }}
                    className={`${(row.original as any).unowned && !(row.original as any).showAll && 'opacity-20 pointer-events-none'} ${
                      isSelected && 'bg-brand/[.3]'
                    }`}>
                    {row.cells.map((cell) => {
                      return <Td {...cell.getCellProps([{ className: cell.column.className ?? ' ' }])}>{cell.render('Cell')}</Td>;
                    })}
                  </Tr>
                </Fragment>
              );
            })}
          </Tbody>
        </table>
      </div>
    );
  })
);
