function Button({
  color,
  size,
  text,
  type,
  icon,
  className,
  ...props
}: {
  color?: string;
  size?: string;
  icon?: React.ReactNode;
  text: React.ReactNode;
  className?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>) {
  let themeColor: string;
  let themeSize: string;
  switch (color) {
    case 'green':
      themeColor = 'bg-success border-b-2 border-success-dark text-white';
      break;
    case 'white':
      themeColor = 'bg-white text-brand-dark border border-slate-200 hover:bg-brand-offwhite hover:shadow-inner';
      break;
    case 'offwhite':
      themeColor = 'bg-brand-offwhite/50 text-brand-dark border border-slate-200 hover:bg-brand-offwhite hover:shadow-inner';
      break;
    case 'navy':
      themeColor = 'bg-brand border border-brand-dark text-white';
      break;
    case 'yellow':
      themeColor = 'bg-yellow-500 border border-brand-dark text-white';
      break;
    case 'orange':
      themeColor = 'bg-orange-500 border border-brand-dark text-white';
      break;
    case 'red':
      themeColor = 'bg-red-500 border border-red-500 text-white';
      break;
    case 'purple':
      themeColor = 'bg-brand-purple border border-brand-purple text-white';
      break;
    case 'pale':
      themeColor = 'bg-brand-pale text-brand-electric border border-brand-electric';
      break;
    case 'pale-red':
      themeColor = 'bg-red-50 text-red-500 border border-red-500';
      break;
    case 'clear':
      themeColor = 'text-slate-500 underline hover:text-slate-8';
      break;
    default:
      themeColor = 'bg-brand-electric border-b-2 border-brand text-white';
  }
  switch (size) {
    case 'xs':
      themeSize = ' text-sm font-semibold py-1 px-4';
      break;
    case 'sm':
      themeSize = ' font-semibold py-2 px-8';
      break;
    case 'md':
      themeSize = ' font-semibold py-3 px-8';
      break;
    default:
      themeSize = ' text-lg font-bold py-3 px-8 mb-3';
  }

  return (
    <button
      className={`${themeColor + themeSize} flex rounded transition disabled:opacity-50 hover:opacity-70 items-center justify-center ` + className}
      type={type ?? 'button'}
      {...props}>
      {icon}
      {text}
    </button>
  );
}

export default Button;
